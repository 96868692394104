import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby";

// Styles
import "./FeaturedPosts.scss"

// Components
import SectionHeader from "../../components/SectionHeader"
import SectionWrapper from "../../components/SectionWrapper"


import {CustomLinks} from "../common/menuUtils"

const ServiceCard = ( { moduleData, sectionHeaderData, className } ) => {

    return (
        <>
            { moduleData && moduleData.length > 0 && (
                <SectionWrapper className={ `featured-posts-wrapper white_bg ${ className || '' }` }>
                    <Container>
                        <div className='featured-posts-header-section'>
                            <SectionHeader
                                sectionHeaderData={ sectionHeaderData }
                            />
                        </div>
                        <div className="sub-section-wrapper featured-posts-body">
                            <Row>
                                <Col lg={ 8 } md={ 12 } xs={ 12 }>
                                    <Row>
                                        <Col className="featured-posts-single-tile" sm={ 6 } xs={ 12 }>
                                            <SinglePost postData={ moduleData[ 0 ] } DisplayType={"long-post"} />
                                        </Col>
                                        <Col className="featured-posts-double-tile d-none d-sm-flex d-md-flex" sm={ 6 } xs={ 12 }>
                                            {moduleData[ 1 ] && <SinglePost postData={ moduleData[ 1 ] } DisplayType={"short-post"} />}
                                            
                                            {moduleData[ 2 ] && <SinglePost postData={ moduleData[ 2 ] } DisplayType={"short-post"} />}
                                            
                                        </Col>
                                    </Row>
                                </Col>
                                {moduleData[ 3 ] && 
                                    <Col className="featured-posts-single-tile custom-post-height d-none d-sm-flex d-md-flex" lg={ 4 } md={ 12 } xs={ 12 }>
                                        <SinglePost postData={ moduleData[ 3 ] } DisplayType={"long-post"} />
                                    </Col>
                                }
                                
                            </Row>
                        </div>
                    </Container>
                </SectionWrapper>
            ) }
        </>
    )
}

export default ServiceCard

const SinglePost = ( { postData, DisplayType } ) => {

    const { Label, Image } = postData;

    return (
        <div className="featured-posts-wrapper-box">
            <Link to={postData.Link}>
                <div
                    className={ `featured-posts-single-post ${ DisplayType || '' }` }
                    style={ {
                        backgroundImage: `url(${Image.url})`
                    } }
                >
                    <div className="overlay_bg"></div>
                   
                    <div className="featured-posts-content">
                        <h4 className="featured-posts-title">{ Label }</h4>
                    </div>
                </div>
            </Link>
        </div>
    )
}
