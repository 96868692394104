import React,{useState, useEffect} from "react"
import { Container, Row, Col } from "react-bootstrap"

// Styles
import "./TeamDetails.scss"

// Components
import SectionWrapper from "../../../components/SectionWrapper"
import FilterTeam from "components/FilterContent/FilterTeam"
import SingleTeamCard from "components/SingleTeamCard"
import CustomButton from "components/CustomButton"

import {GetTeams, GetTeamCategories} from "../../../queries/common_use_query"

const postsPerPage = 20;
let arrayForHoldingPosts = [];

const TeamDetails = ( { teamDetailsData } ) => {

    const layout = {
        lg: 3,
        md: 6,
        sm: 12
    };

    const [postsToShow, setPostsToShow] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [next, setNext] = useState(postsPerPage);
    const [ selectedCategory, setSelectedCategory ] = useState("All");

    const {loading, error, data} = GetTeams();
    const {data:cat_data} = GetTeamCategories();

    // useEffect(() => {
    //     setPostPerPageLoad(13);
    // }, []);

    useEffect(() => {
        if(data && data?.teams && data.teams.length > 0){
            var filter_by_cat = [];
            emptyArraySet();
            if(selectedCategory === "All"){
                setTeamList(data?.teams);
            } else{
                data.teams.map((item, index) => {
                    if(item?.teams_categories.length > 0 && item?.teams_categories?.some(item2 =>  item2.Title === selectedCategory.Title)){
                        filter_by_cat.push(item);
                    }
                })
                setTeamList(filter_by_cat);
            }
            
        } 
        
    }, [data, selectedCategory]);
    
    const loopWithSlice = (start, end) => {
        const sliced_Posts = teamList.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...sliced_Posts];
        setPostsToShow(arrayForHoldingPosts);
    };

    const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
    };

    useEffect(() => {
        loopWithSlice(0, postsPerPage);
    }, [teamList]);

    const emptyArraySet = () => {
        arrayForHoldingPosts = [];
        setNext(postsPerPage);
    }

    return (
        <SectionWrapper className="team-details-wrapper">
            <Container>
                <Row>
                    <Col>
                        <FilterTeam
                            categories={ cat_data && cat_data.teamsCategories}
                            content={ postsToShow }
                            CardComponent={ SingleTeamCard }
                            layout={ layout }
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                            allCount={data && data?.teams && data.teams.length}
                        />

                        {next < teamList.length && 
                        <CustomButton
                            variant="outline"
                            onClick={() => {handleShowMorePosts(); }}
                            value="LOAD MORE"
                            classNames="team_load_mre"
                        />
                        }
                    </Col>
                </Row>
            </Container>
        </SectionWrapper>
    )
}

export default TeamDetails

const getCategories = ( teamDetailsData = [] ) => {
    let uniqueCategories = [];
    let categories = [];
    if ( teamDetailsData && teamDetailsData.length > 0 ) {
        teamDetailsData.forEach( ( teamDetailsItem ) => {
            const { Tags } = teamDetailsItem;
            if ( Tags && Tags.length > 0 ) {
                Tags.forEach( ( TagsItem ) => {
                    if ( uniqueCategories.includes( TagsItem ) ) {
                        categories[ TagsItem ] = categories[ TagsItem ] + 1;
                    } else {
                        uniqueCategories.push( TagsItem );
                        categories[ TagsItem ] = 1;
                    }
                } )
            }
        } )
    }
    let categoriesArray = [];
    categoriesArray.push(
        {
            name: 'All',
            count: teamDetailsData.length
        }
    )
    Object.keys( categories ).forEach( ( categoryItem ) => {
        categoriesArray.push(
            {
                name: categoryItem,
                count: categories[ categoryItem ]
            }
        )
    } )
    return categoriesArray;
}
