import React, {useState} from "react"

// Styles
import "bootstrap/dist/css/bootstrap.min.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "../scss/global.scss"
import "../scss/styles.scss"


// Components
import Layout from "../components/layout"
import Banner from "../components/Banner"
import PageBanner from "../components/PageBanner"

import SearchForm from "../layout/Home/SearchForm"
import FeaturedProperties from "../layout/Home/FeaturedProperties"
import MiddleContentBlock from "../layout/Home/AboutUs"
import Fundamentals from "../layout/Home/Fundamentals"
import ExploreOurAreas from "../layout/Home/ExploreOurAreas"
import WhatsHappening from "../layout/Home/WhatsHappening"
import WhatClientsSay from "../layout/Home/WhatClientsSay"
import NewsDetails from "../layout/NewsAndInsights/NewsDetails"
import CharitiesWeSupport from "layout/DiscoverMarylebone/CharitiesWeSupport"
import PhotoGallery from "layout/DiscoverMarylebone/About/PhotoGallery"
import WhatPeopleSay from "layout/Testimonials/WhatPeopleSay"
import Accreditations from "layout/Testimonials/WhatPeopleSay/Accreditations"
import TeamDetails from "layout/AboutTeam/TeamDetails"


import IntroSection from "../components/IntroSection"
import PlayVideo from '../components/Play/PlayVideo';
import BreadCrumbs from "../components/BreadCrumbs"
import ScrollTabs from "../components/ScrollTabs"
import PropertiesSection from "../components/PropertiesSection"
import FormSection from "../components/FormsSection"
import ContentSection from "../components/ContentSection"
import ServiceOffer from "../components/ServiceOffer"
import AreaGuides from "../components/AreaGuides"
import AdsBanner from "../components/FooterBanner/"
import JobSection from "../components/JobSection"
import OurTeamSection from "../components/OurTeamSection"


function CommonTemplate(props) {
	const [isPlay, setPlay] = useState(false);
	const [videoUrl, setVideoUrl] = useState('');
	
	const setPlayVideo = (video_url) =>{
		setPlay(true);
		setVideoUrl(video_url);
	}


	var GQLPage = props?.GQLPage;
  	var GQLModules = props?.GQLModules;

	var globalModule = props?.globalModule;

	//console.log("GQLPage", GQLPage?.all_menu);

	var parent_menu_id = GQLPage?.Alias === "about-resiland" ||  GQLPage?.Alias === "our-services" ? GQLPage?.all_menu?.id : GQLPage?.all_menu?.Parent_Menu?.id;
  
	return <>
		<Layout variant={GQLPage.Layout === "Default" ? "transparent" : ""} page_class={GQLPage.Page_CSS_Class} layout={GQLPage.Layout} Meta_Title={GQLPage.Meta_Title} Meta_Description={GQLPage.Meta_Description} menu_slug={GQLPage?.all_menu?.Primary_URL}>

			{GQLPage.Layout === "Static_Page" && GQLPage.Banner_Image && 
				<PageBanner GQLPage={GQLPage} />
			}
			{(GQLPage.Layout === "Static_Page" || GQLPage.Layout === "Without_Banner") && 
			<BreadCrumbs menu_data={GQLPage.all_menu} /> }

			{(GQLPage?.all_menu && (GQLPage?.all_menu?.Parent_Menu !== null && GQLPage?.all_menu?.Sub_Menu === null && GQLPage?.all_menu?.Secondary_Sub_Menu === null) || GQLPage?.Alias === "about-resiland" || GQLPage?.Alias === "our-services") && 
				<ScrollTabs menu_id={parent_menu_id} Page_Name={GQLPage?.all_menu?.Page_Name} GQLPage={GQLPage} />}

			{(GQLPage.Layout === "Without_Banner" && GQLPage.Content) && <ContentSection GQLPage={GQLPage} /> }
			
			{
			GQLModules.map((Module, i) => {
				return( <>
					{Module.__typename === "GLSTRAPI_ComponentComponentSliderComponent" && 
						<>
							<Banner Module={Module} />
							<SearchForm />
						</>
					}
					
					{(Module.__typename === "GLSTRAPI_ComponentComponentIntroComponent") && 
					<IntroSection Module={ Module } RelatedPages={GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentRelatedPagesComponent")} Accordians={GQLModules.filter(Module => Module.__typename === "GLSTRAPI_ComponentComponentAccordianComponent")} Alias={GQLPage?.Alias} setPlayVideo={setPlayVideo}  />}

					{(Module.__typename === "GLSTRAPI_ComponentComponentVacanciesComponent") && 
					<JobSection Module={ Module } />}

					{Module.__typename === "GLSTRAPI_ComponentComponentFeaturedProperties" && Module.Show_Featured_Properties && <FeaturedProperties Module={Module} />}

					{Module.__typename === "GLSTRAPI_ComponentComponentMiddleContentBlock" && 
					<MiddleContentBlock Module={ Module } setPlayVideo={setPlayVideo} />}
					
					{Module.__typename === "GLSTRAPI_ComponentComponentShowFundamentals" && Module.Show_Component && 
					<Fundamentals Module={Module} globalModule={globalModule.Fundamentals_Component} /> }

					{Module.__typename === "GLSTRAPI_ComponentComponentShowContactForm" && Module.Show_Component && <FormSection Module={Module} />}

					{Module.__typename === "GLSTRAPI_ComponentComponentShowExploreArea" && Module.Show_Areas && <ExploreOurAreas Module={ Module } /> }

					{Module.__typename === "GLSTRAPI_ComponentComponentWhatsHappningComponent" &&  <WhatsHappening Module={ Module } /> }

					{Module.__typename === "GLSTRAPI_ComponentComponentRecentProjectsComponent" && <PropertiesSection Module={ Module } /> }

					{Module.__typename === "GLSTRAPI_ComponentComponentOurBuildsComponent" &&  <FeaturedProperties Module={Module} type="our_building" />}

					{Module.__typename === "GLSTRAPI_ComponentComponentTestimonialsComponent" && <WhatClientsSay Module={Module} />}

					{Module.__typename === "GLSTRAPI_ComponentComponentServiceComponent" && 
					<ServiceOffer Module={ Module } /> }

					{Module.__typename === "GLSTRAPI_ComponentComponentCollectionComponent" && Module.Select_Collection === "Area_Guides" && <AreaGuides Title="Featured Areas" ViewAll={false}/> }

					{Module.__typename === "GLSTRAPI_ComponentComponentCollectionComponent" && Module.Select_Collection === "News" && <NewsDetails /> }

					{Module.__typename === "GLSTRAPI_ComponentComponentCollectionComponent" && Module.Select_Collection === "Testimonials" && <WhatPeopleSay Module={ Module } /> }

					{Module.__typename === "GLSTRAPI_ComponentComponentCollectionComponent" && Module.Select_Collection === "Accreditations" && <Accreditations Module={ Module } /> }

					{Module.__typename === "GLSTRAPI_ComponentComponentCollectionComponent" && Module.Select_Collection === "Teams" && <TeamDetails />}

					{Module.__typename === "GLSTRAPI_ComponentComponentBrandLogosComponent" &&<CharitiesWeSupport Module={ Module } />}

					{Module.__typename === "GLSTRAPI_ComponentComponentGalleryComponent" &&<PhotoGallery Module={ Module } />}

					{Module.__typename === "GLSTRAPI_ComponentComponentAdsBlockComponent" && <AdsBanner Module={ Module } type={"work-with-us"} />}

					{Module.__typename === "GLSTRAPI_ComponentComponentNewsletterSubscription" && <AdsBanner Module={ Module } type={"mailing-list"} />}

					{Module.__typename === "GLSTRAPI_ComponentComponentShowTeamComponent" && <OurTeamSection Module={ Module }/>}
				</>)
				})
			}


			<PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoUrl={videoUrl}
                isAutoPlay={1}
            />

		</Layout>
	</>
}

export default CommonTemplate