import React, { useState } from "react"
import { Accordion, Col, Container, Row } from "react-bootstrap";
import HTMLReactParser from "html-react-parser"

import {ImageModule} from '../../modules/Image_Module'

// Styles
import '../IntroSection/CharityStories.scss';


const CharityStories = ( { Module } ) => {

    const [ selectedItem, setSelectedItem ] = useState(0)


    //console.log("Accordian_Module", Module?.Accordian_Module);

    return (
        <div className="charity-stories-accordion">
            {Module.Title && <h2 className="general-heading">{Module.Title}</h2>}

            {Module.Content && <div className="sub_heading">{HTMLReactParser(Module.Content)}</div>}

            {Module?.Accordian_Module?.length > 0 && 
                <Accordion>
                    {
                        Module.Accordian_Module.map( ( accordionListItem, accordionListIndex ) => {
                            return (
                                <AccordionItem
                                    key={ accordionListIndex }
                                    accordionItemData={ accordionListItem }
                                    accordionListIndex={ accordionListIndex }
                                    selectedItem={ selectedItem }
                                    setSelectedItem={ setSelectedItem }
                                />
                            )
                        } )
                    }
                </Accordion>
            }
            
        </div>
    )
}

export default CharityStories

const AccordionItem = ( data ) => {

    const { accordionItemData, accordionListIndex, selectedItem, setSelectedItem } = data;
    const { id, heading, body = {} } = accordionItemData;
    const { Image, Heading, Content } = body;

    const isSelectedItem = accordionItemData.id === selectedItem.id;
    const iconSource = isSelectedItem ? 'minus-icon-black.png' : 'plus-icon-black.png';

    return (
        <div className="accordion-item">
            <Accordion.Toggle
                eventKey={ String( accordionListIndex ) }
                onClick={ () => isSelectedItem ? setSelectedItem( {} ) : setSelectedItem( accordionItemData ) }
                className="accordion-header"
            >
                <span>
                    { accordionItemData.Heading }
                </span>
                <span>
                    <img
                        src={ require( `../../images/${ iconSource }` ).default }
                        width="auto"
                        height="auto"
                        alt="Plus Icon"
                    />
                </span>
            </Accordion.Toggle>
            <Accordion.Collapse
                eventKey={ String( accordionListIndex ) }
                className="accordion-body"
            >
                <div className="accordion-content">
                    {accordionItemData.Image && 
                        <div className="charity-story-logo">
                            {/* <img
                                src={ require( `../../images/accordion-logo/${ logoSource }` ).default }
                                width="auto"
                                height="auto"
                                alt="Company Logo"
                            /> */}
                            <ImageModule ImageSrc={accordionItemData.Image} />
                        </div>
                    }
                    
                    <div className="charity-story-content">
                        {accordionItemData.Description && HTMLReactParser(accordionItemData.Description)}
                    </div>
                </div>
            </Accordion.Collapse>
        </div>
    )
}
