import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import dateFormat from "dateformat";

// Styles
import "./FeaturedPosts.scss"

// Components
import SectionHeader from "../../components/SectionHeader"
import SectionWrapper from "../../components/SectionWrapper"
import { Link } from "@reach/router";

import {CustomLinks} from "../common/menuUtils"

const FeaturedPosts = ( { featuredPostsData, sectionHeaderData, className } ) => {

    return (
        <>
            { featuredPostsData && featuredPostsData?.length > 0 && (
                <SectionWrapper className={ `featured-posts-wrapper ${ className || '' }` }>
                    <Container>
                        <div className='featured-posts-header-section'>
                            <SectionHeader
                                sectionHeaderData={ sectionHeaderData }
                            />
                        </div>
                        <div className="sub-section-wrapper featured-posts-body">
                            <Row>
                                <Col lg={ 8 } md={ 12 } xs={ 12 }>
                                    <Row>
                                        <Col className="featured-posts-single-tile" sm={ 6 } xs={ 12 }>
                                            <SinglePost postData={ featuredPostsData[ 0 ]?.news_insights } DisplayType={"long-post"} />
                                        </Col>
                                        <Col className="featured-posts-double-tile d-none d-sm-flex d-md-flex" sm={ 6 } xs={ 12 }>
                                            {featuredPostsData[ 1 ] && <SinglePost postData={ featuredPostsData[ 1 ]?.news_insights } DisplayType={"short-post"} />}
                                            
                                            {featuredPostsData[ 2 ] && <SinglePost postData={ featuredPostsData[ 2 ]?.news_insights } DisplayType={"short-post"} />}
                                            
                                        </Col>
                                    </Row>
                                </Col>
                                {featuredPostsData[ 3 ] && 
                                    <Col className="featured-posts-single-tile custom-post-height d-none d-sm-flex d-md-flex" lg={ 4 } md={ 12 } xs={ 12 }>
                                        <SinglePost postData={ featuredPostsData[ 3 ].news_insights } DisplayType={"long-post"} />
                                    </Col>
                                }
                                
                            </Row>
                        </div>
                    </Container>
                </SectionWrapper>
            ) }
        </>
    )
}

export default FeaturedPosts

const SinglePost = ( { postData, DisplayType } ) => {

    return (
        <>{postData && postData?.Title && 
            <div className="featured-posts-wrapper-box">
                <Link to={`/${CustomLinks.news_media}/${postData.URL}/`}>
                    <div className={ `featured-posts-single-post ${ DisplayType || '' }` }>
                        <div className={ `post_image` }
                            style={ {
                                backgroundImage: `url(${postData.Image.url})`
                            } }
                        ><div className="overlay_bg"></div></div>

                        <span className="featured-posts-post-category">
                            { postData.news_categories[0].Title }
                        </span>
                        <div className="featured-posts-content">
                            <h4 className="featured-posts-title">{ postData.Title }</h4>
                            <span className="featured-posts-publish-time">{ dateFormat(postData.Published_Date, "dS mmmm yyyy") }</span>
                        </div>
                    </div>
                </Link>
            </div>
        }
        </>
        
    )
}
