import * as React from "react"
import {Link} from "gatsby"
import { Card } from "react-bootstrap"

// Styles
import "./SingleTeamCard.scss"

// Components
import {CustomLinks} from '../../components/common/menuUtils'
import {ImageModule} from '../../modules/Image_Module'

const SingleTeamCard = ( singleTeamCardData = {} ) => {

    const { Title, Designation, image, URL } = singleTeamCardData;

    var team_link = `/${CustomLinks.our_team}/${URL}/`;

    const ImageRenderList = ({item}) => {
        let processedImages = JSON.stringify({});
        if (item?.imagetransforms?.image_Transforms) {
            processedImages = item?.imagetransforms?.image_Transforms;
        }

        return (
            <ImageModule ImageSrc={item.image} classNames={"card-img-top"} imagetransforms={processedImages} renderer="srcSet" imagename="teams.image.listing" strapi_id={item.id} />                           
        )
    }
    return (
        <div className="single-team-card-wrapper">
            <Card>

                <div class="card-container-image">
                    <Link to={`${team_link}`}>
                        {/* <ImageModule ImageSrc={image} classNames="card-img-top" /> */}
                        <ImageRenderList item={singleTeamCardData} />                        
                    </Link>
                </div>

                <Card.Body>
                    <Card.Title>
                        <Link to={`${team_link}`}>
                        { Title }
                        </Link>
                    </Card.Title>
                    <Card.Text className="card-content">
                        { Designation }
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}

export default SingleTeamCard
