import * as React from 'react';

// Styles
import '../Banner/Banner.scss';

// Import react-slick for Rotational Banner
import Slider from 'react-slick';
import { Col, Container, Row } from "react-bootstrap";


const Banner = ( { bannerData, Module } ) => {

	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
	};

	//console.log("Module ==>", Module.Slider_Module);
	return (
		<div className="banner-wrapper">
			<Slider
				{ ...settings }
			>
				{ Module?.Slider_Module?.length > 0 &&
					Module.Slider_Module.map( ( banner, bannerIndex ) => {
						return (
							<div
								key={ bannerIndex }
								className="slider-content"
							>
								<div
									className="slider-background"
									style={ {
										background: `url(${banner.Banner_Image.url}) no-repeat`,
									} }
								>
									<Container className="slider-item-wrapper">
										<Row>
											<Col className="slider-item">
												<h1>{ banner.Banner_Heading }</h1>
												{banner.Banner_Content && <p>{ banner.Banner_Content }</p>}
											</Col>
										</Row>
									</Container>
								</div>
							</div>
						);
					} ) }
			</Slider>
		</div>
	);
};

export default Banner;
