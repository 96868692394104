import * as React from "react"
import { Container, Row, Col, Section } from "react-bootstrap"
import { Link } from "@reach/router";

import {ImageModule} from '../../modules/Image_Module'
import { menuLinks } from "../common/menuUtils";

// Styles
import '../../layout/DiscoverMarylebone/PropertyTypes/PropertyTypes.scss';


const RelatedPageSection = ( { Module, ColLg, ColMd } ) => {

    return (
    <div className="property-types-wrapper">
            <Row className="property-types-tiles">
                {
                    Module?.Related_Pages_Module?.length > 0 && Module.Related_Pages_Module.map( ( propertyTypesItem, propertyTypesIndex ) => {

                        var link = propertyTypesItem.all_menus ? menuLinks(propertyTypesItem.all_menus) : "/" 
                        return (
                            <Col
                                key={ propertyTypesIndex }
                                lg={ColLg}
                                md={ColMd}
                                className={ ( propertyTypesIndex > 3 ) ? 'hide-on-tab-and-mobile' : ( propertyTypesIndex > 1 ) ? 'hide-on-mobile' : '' }
                            >
                                <Link to={link} className="col-12 no_bdr">
                                    <div className="property-type-tile">
                                        <div className="property-type-image">
                                            <ImageModule ImageSrc={propertyTypesItem?.all_menus?.Image} />
                                        </div>
                                        <div className="property-type-content">
                                            <span>
                                                { propertyTypesItem.Label }
                                            </span>
                                            <img className="right_arw"
                                                src={ require( `../../images/arrow_right.svg` ).default }
                                                alt="right-arrow"
                                            />
                                        </div>
                                    </div>
                                </Link>
                            </Col>
                        )
                    } )
                }
            </Row>
    </div>
    )
}

export default RelatedPageSection