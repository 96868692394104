import * as React from "react"
import { Container } from "react-bootstrap"

// Styles
import "./Fundamentals.scss"

// Components
import Tiles from "../../../components/Tiles/index"
import SectionHeader from "../../../components/SectionHeader"
import SectionWrapper from "../../../components/SectionWrapper"

const Fundamentals = ( { globalModule } ) => {

	const sectionHeaderData = {
		Title: globalModule.Heading,
		SubTitle: globalModule.Content,
		showSubTitleAlways: true
	}

	return (
		<>
			{ globalModule && (
				<SectionWrapper className="fundamentals-wrapper">
					<Container>
						<div className='fundamentals-header-section'>
							<SectionHeader
								sectionHeaderData={ sectionHeaderData }
							/>
						</div>
						{ ( globalModule.Fundamentals_Module && globalModule.Fundamentals_Module ) && (
							<div className='sub-section-wrapper fundamentals-content-section'>
								<Tiles
									tilesData={ globalModule.Fundamentals_Module }
								/>
							</div>
						) }
					</Container>
				</SectionWrapper>
			) }
		</>
	)
}

export default Fundamentals
