import React, {useState, useEffect} from "react"
import { Container, Row, Col } from "react-bootstrap"

// Styles
import "./WhatPeopleSay.scss"

// Components
import SectionWrapper from "../../../components/SectionWrapper"
import SingleTestimonialCard from "components/SingleTestimonialCard"
import CustomButton from "components/CustomButton"

import {GetTestimonials} from "../../../queries/common_use_query"

const postsPerPage = 12;
let arrayForHoldingPosts = [];

const WhatPeopleSay = () => {
    const [postsToShow, setPostsToShow] = useState([]);
    const [testiList, setTestiList] = useState([]);
    const [next, setNext] = useState(postsPerPage);


    const {loading, error, data} = GetTestimonials();

    const loopWithSlice = (start, end) => {
        const sliced_Posts = testiList.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...sliced_Posts];
        setPostsToShow(arrayForHoldingPosts);
    };

    const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
    };

    useEffect(() => {
        loopWithSlice(0, postsPerPage);
    }, [testiList]);

    //console.log("data", data);
    useEffect(() =>{
        data && data?.testimonials?.length > 0 && setTestiList(data?.testimonials)
    }, [data])

    return (
        <SectionWrapper className="what-people-say-wrapper">
            <Container>
                <div
                    className="what-people-say-body"
                    data-masonry='{"percentPosition": true }'
                >
                    {
                        postsToShow?.length > 0 && postsToShow.map( ( whatPeopleSayItem, whatPeopleSayIndex ) => {
                            return (
                                <div className="testiCard"
                                >
                                    <SingleTestimonialCard
                                        singleTestimonialCardData={ whatPeopleSayItem }
                                    />
                                </div>
                            )
                        } )
                    }
                </div>
                <div>
                    {next < testiList.length &&  
                        <CustomButton
                            variant="outline"
                            onClick={handleShowMorePosts}
                            value="LOAD MORE"
                        />
                    }
                </div>
            </Container>
        </SectionWrapper>
    )
}

export default WhatPeopleSay