import * as React from "react"
import { Row, Col } from "react-bootstrap"
import { Link } from "@reach/router";

import {ImageModule} from '../../modules/Image_Module'

// Styles
import "../Tiles/Tiles.scss"

const Tiles = ( { tilesData } ) => {
	return (
		<Row className="tiles-container">
			{ tilesData &&
				tilesData.map( ( tile, tileIndex ) => {
					return (
						<Col
							key={ tileIndex }
							lg={ 3 }
							md={ 6 }
						>
							<div className="tile-wrapper">
								<div>
									<div className="title-image">
										{/* <img
											src={ require( `../../images/${ tile.FeatureImage }` ).default }
											alt={ tile.FeatureTitle || "featured-image" }
										/> */}
										{/* <i className={"icon " + tile.FeatureIcon}></i> */}
										<ImageModule ImageSrc={tile.Icon} />
									</div>
									<div className="title-content">
										<h3 className="tile-title">
											{ tile.Title }
										</h3>
										<p className="tile-subtitle">
											{ tile.Content }
										</p>
									</div>
								</div>
								<Link to={ tile.CTA_Link }>{ tile.CTA_Label }</Link>
							</div>
						</Col>
					)
				} )
			}
		</Row>
	)
}

export default Tiles
