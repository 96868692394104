import * as React from 'react';
import ServiceCard from "../FeaturedPosts/ServiceCard";


// Styles
import '../../layout/Home/WhatsHappening/WhatsHappening.scss';

const ServiceOffer = ( { Module } ) => {

    const sectionHeaderData = {
        Title: Module.Title,
        Link: {
            Text: Module.CTA_Label,
            Href: Module.CTA_Link,
        }
    }

    return (
        <ServiceCard
            sectionHeaderData={ sectionHeaderData }
            moduleData={ Module.Service_Module }
            className="whats-happening-wrapper"
        />
    );
};

export default ServiceOffer;
