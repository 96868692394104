import * as React from "react"
import ShowMoreText from 'react-show-more-text';
import HTMLReactParser from "html-react-parser"
// Styles
import "./SingleTestimonialCard.scss"

import {ImageModule} from '../../modules/Image_Module'

const SingleTestimonialCard = ( { accCardData } ) => {
    const { Content, Image, Website_Link } = accCardData;

    return (
        <div className="single-testimonial-card-wrapper">
            <div className="card">
                <div className="card-content acc_content">
                    <div className="card-image">
                        <ImageModule ImageSrc={Image} classNames="card-img-top" />
                    </div>
                    <div className="card-description">
                        {Content && 
                            <ShowMoreText
                                lines={5}
                                more='View More'
                                less='View Less'
                                className='content-css'
                                anchorClass='btn-more review-more'
                                expanded={false}
                            > 

                                {HTMLReactParser(Content)}
                            </ShowMoreText> 
                        }

                        <a href={Website_Link} target="_blank" className="card_link">{Website_Link}</a>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default SingleTestimonialCard;