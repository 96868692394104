import * as React from 'react';
import FeaturedPosts from "../../../components/FeaturedPosts";

import {CustomLinks} from "../../../components/common/menuUtils"

// Styles
import './WhatsHappening.scss';

const WhatsHappening = ( { Module } ) => {

    const sectionHeaderData = {
        Title: Module.Title,
        Link: {
            Text: 'View All',
            Href: "/"+CustomLinks.news_media+"/"
        }
    }

    return (
        <>
        {Module?.News_Insight_Modules?.length > 0 && 
            <FeaturedPosts
                sectionHeaderData={ sectionHeaderData }
                featuredPostsData={ Module.News_Insight_Modules }
                className="whats-happening-wrapper"
            />
        }
        </>
        
    );
};

export default WhatsHappening;
