import * as React from 'react';
import { Container, Col, Row } from "react-bootstrap";

// Components
import SectionWrapper from "../SectionWrapper";
import SectionHeader from "../SectionHeader";
import SingleTeamCard from "../SingleTeamCard"

import {FeaturedTeams} from "../../queries/common_use_query";

// Styles
import '../../layout/Home/FeaturedProperties/FeaturedProperties.scss';

const FeaturedTeamsSection = ( { Module } ) => {

    const {data} = FeaturedTeams();

    const defaultLayout = {
        lg: 3,
        md: 6,
        sm: 12
    };
    
    const sectionHeaderData = {
        Title: Module.Heading,
        Link: {
            Text: Module.CTA_Label,
            Href: Module.CTA_Link
        }
    }

    //console.log("Module", Module.Property_Module);

    return (
        <SectionWrapper className={`featured-properties-wrapper`}>
            <Container>
                <div className='property-cards-header-section'>
                    <SectionHeader
                        sectionHeaderData={ sectionHeaderData }
                    />
                </div>

                <div className="sub-section-wrapper property-cards-body-section">
                    <Row>
                    {data && data?.teams?.length > 0 && data?.teams?.map((item, index) =>{
                        return(
                            <Col key={ index } { ...defaultLayout }>
                                <SingleTeamCard {...item}/>
                            </Col>
                        )
                    })}
                    </Row>
                </div>
            </Container>
        </SectionWrapper>
    );
};

export default FeaturedTeamsSection;
