import * as React from "react"
import ShowMoreText from 'react-show-more-text';
import dateFormat from "dateformat";
import HTMLReactParser from "html-react-parser"
// Styles
import "./SingleTestimonialCard.scss"

import {ImageModule} from '../../modules/Image_Module'

const SingleTestimonialCard = ( { singleTestimonialCardData } ) => {
    const { Content } = singleTestimonialCardData;

    return (
        <div className="single-testimonial-card-wrapper">
            <div className="card">
                <div className="card-content">
                    <div className="card-description">
                        {Content && 
                            <ShowMoreText
                                lines={4}
                                more='View More'
                                less='View Less'
                                className='content-css'
                                anchorClass='btn-more review-more'
                                expanded={false}
                            > 

                                {HTMLReactParser(Content)}
                            </ShowMoreText> 
                        }
                    </div>
                    <div>
                        <ClientDetails
                            clientProfileData={ singleTestimonialCardData }
                        />
                    </div>
                </div>
                <div className="quote-image">
                    <img
                        src={ require( `../../images/quote.svg` ).default }
                        alt="Quote Image"
                    />
                </div>
            </div>
        </div>
    )
}

export default SingleTestimonialCard;

const ClientDetails = ( { clientProfileData } ) => {
    const { Image, Name, Location, Published_Date, Client_Name } = clientProfileData;
    return (
        <div className="client-details-wrapper">
            <div className="client-profile-picture">
                {/* <img
                    src={ require( `../../images/${ Image }` ).default }
                    alt={ ClientName || "name" }
                /> */}
                <ImageModule ImageSrc={Image} classNames="card-img-top" />
            </div>
            <div>
                <div className="client-details">
                    <div className="client-name">{ Client_Name }</div>
                    <div className="client-place">{ Location }</div>
                    {Published_Date && <div className="date">{dateFormat(Published_Date, "mmm dd, yyyy") }</div>}
                </div>
            </div>
        </div>
    )
}