import React, { useRef, useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Slider from 'react-slick';
import {Link} from 'gatsby';
import NoImage from "../../../images/no-image.png"

// Hooks
import usePreviousData from "customHooks/usePreviousData";
import useWindowDimensions from "customHooks/useWindowDimensions";

// Styles
import "./ExploreOurAreas.scss"

// Components
import SectionWrapper from "../../../components/SectionWrapper"
import SectionHeader from "../../../components/SectionHeader"

import {FeaturedAreaGuides} from "../../../queries/common_use_query"

import {CustomLinks} from "../../../components/common/menuUtils"

const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    adaptiveHeight: true
};

const ExploreOurAreas = ({Module}) => {

    //const tileImages = exploreOurAreasData;
    const [tileImages, setTileImages] = useState([]);
    const [featuredImages, setFeaturedImages] = useState([]);
    const [allAreas, setAllAreas] = useState([]);

    const [ count, setCount ] = useState( 0 );

    const {loading, error, data} = FeaturedAreaGuides();

    const sectionHeaderData = {
        Title: Module.Title,
        SubTitle: Module.Content,
        showSubTitleAlways: true,
        isInline: true,
    }

    const { screenWidth } = useWindowDimensions();

    const updateNavigationIcon = ( event, eventType ) => {
        let navigationIcon = event.target.getElementsByTagName( 'img' );
        if ( navigationIcon && navigationIcon[ 0 ] ) {
            const icon = eventType === 'enter' ? 'navigation-left-black-icon.png' : 'navigation-left-white-icon.png';
            navigationIcon[ 0 ].src = require( `../../../images/${ icon }` ).default;
        }
    }

    const updateImage = ( type ) => {
        if ( type === 'prev' ) {
            setCount( count - 1 );
        } else {
            setCount( count + 1 );
        }
    }

    const paginate = (arr, size) => {
        return arr.reduce((acc, val, i) => {
          let idx = Math.floor(i / size)
          let page = acc[idx] || (acc[idx] = [])
          page.push(val)
      
          return acc
        }, [])
    }

    useEffect(()=>{
        // let array = [1, 2, 3, 4, 5]
        // let page_size = 2
        // let pages = paginate(array, page_size)

        var featuredArea = [];
        var otherArea = [];
        var allArea = [];

        if(data && data?.areaGuides?.length > 0){
            data?.areaGuides?.map((item, index) => {
                if(index < 2){
                    featuredArea.push(item)
                } else{
                    otherArea.push(item)
                }
                allArea.push(item)
            })
        }

        // All Areas
        if(allArea && allArea?.length > 0){
            setAllAreas(allArea);
            //console.log("areaGuides_", areaGuides_3)    // all pages
        }

        // Featured Areas
        if(featuredArea && featuredArea?.length > 0){
            var featAreaGuide = paginate(featuredArea, 2);
            setFeaturedImages(featAreaGuide);
            //console.log("areaGuides_", areaGuides_3)    // all pages
        }


        // Other Areas
        var otherAreaPagination = otherArea && otherArea?.length > 0 ? Math.round(otherArea?.length / 2) : 2;
        //console.log("otherAreaPagination", otherAreaPagination);
        if(otherArea && otherArea?.length > 0){
            var areaGuides_1 = paginate(otherArea, otherAreaPagination);
            var areaGuides_2 = [];
            areaGuides_1 && areaGuides_1.length > 0 && areaGuides_1.map((item, index) => {
                areaGuides_2.push(paginate(item, 2));
            })
            setTileImages(areaGuides_2);
            //console.log("areaGuides_", areaGuides_3)    // all pages
        }


    },[data])

   // console.log("featuredImages", featuredImages);
    

    return (
        <SectionWrapper
            className="explore-our-areas"
        >
            <Container>
                <Row className="explore-our-areas-header-section">
                    <Col lg={ 9 } sm={ 8 }>
                        <SectionHeader
                            sectionHeaderData={ sectionHeaderData }
                        />
                    </Col>
                    <Col className="navigation-controls-wrapper">
                        <span
                            className="left"
                            onMouseEnter={ e => updateNavigationIcon( e, 'enter' ) }
                            onMouseLeave={ e => updateNavigationIcon( e, 'leave' ) }
                            onClick={ () => updateImage( 'prev' ) }
                        >
                            <img
                                src={ require( `../../../images/navigation-left-white-icon.png` ).default }
                                alt="navigation icon"
                            />
                        </span>
                        <span
                            className="right"
                            onMouseEnter={ e => updateNavigationIcon( e, 'enter' ) }
                            onMouseLeave={ e => updateNavigationIcon( e, 'leave' ) }
                            onClick={ () => updateImage( 'next' ) }
                        >
                            <img
                                src={ require( `../../../images/navigation-left-white-icon.png` ).default }
                                alt="navigation icon"
                            />
                        </span>
                    </Col>
                </Row>
            </Container>
            <div className="sub-section-wrapper">
                <Row className="featured-properties-image-tiles g-0">
                    <TileImage
                        sliderImages={ screenWidth > 767 ? featuredImages[0] : allAreas }
                        className="large"
                        lg={ 3 }
                        md={ 6 }
                        xs={ 12 }
                        count={ count }
                    />
                    { screenWidth > 767 && (
                        <Col className="overflow-hidden">
                            { tileImages.map( ( tileRows, tileRowsIndex ) => {
                                return (
                                    <Row
                                        key={ tileRowsIndex }
                                        className="g-0"
                                    >
                                        { tileRows.map( ( tileImage, tileIndex ) => {
                                            return (
                                                <React.Fragment
                                                    key={ tileIndex }
                                                >
                                                    { ( screenWidth < 992 ) ? (
                                                        <>
                                                            { tileIndex < 2 && (
                                                                <TileImage
                                                                    sliderImages={ tileImage }
                                                                    className="small"
                                                                    count={ count }
                                                                />
                                                            ) }
                                                        </>

                                                    ) : (
                                                        <TileImage
                                                            sliderImages={ tileImage }
                                                            className="small"
                                                            count={ count }
                                                        />
                                                    ) }
                                                </React.Fragment>
                                            )
                                        } ) }
                                    </Row>
                                )
                            } ) }
                        </Col>
                    ) }
                </Row>
            </div>
        </SectionWrapper>
    )
}

export default ExploreOurAreas

const TileImage = ( tileData ) => {

    const sliderRef = useRef();

    const { className, sliderImages = [], count, ...otherProperties } = tileData;

    const prevCount = usePreviousData( count );

    useEffect( () => {
        if ( typeof prevCount !== "undefined" && sliderImages.length > 0 ) {
            if ( count < prevCount ) {
                sliderRef.current.slickPrev();
            } else {
                sliderRef.current.slickNext();
            }
        }
    }, [ count ] ); // eslint-disable-line react-hooks/exhaustive-deps

    //console.log("sliderImages", sliderImages);

    return (
        <>
            { sliderImages.length > 0 &&
                (
                    <Col
                        className="slider-wrapper"
                        { ...otherProperties }
                    >
                        <div
                            className={ `image-slider ${ className || '' }` }
                        >
                            <Slider
                                { ...sliderSettings }
                                ref={ sliderRef }
                            >
                                { sliderImages &&
                                    sliderImages.map( ( sliderImages, sliderImagesIndex ) => {

                                        var image_url = sliderImages?.Banner_Image ? sliderImages?.Banner_Image?.url : NoImage;

                                        var area_link = "/"+CustomLinks.explore_london+"/"+sliderImages.URL+"/"

                                        return (
                                            <div
                                                key={ sliderImagesIndex }
                                                className="slider-content"
                                            >
                                                <Link to={area_link} className="slider_link">
                                                    <div
                                                        className="slider-background"
                                                    >
                                                        <div className={ `post_image` }
                                                            style={ {
                                                                backgroundImage: `url(${image_url})`
                                                            } }
                                                        ><div className="overlay_bg"></div></div>

                                                        <div className="slider-title">
                                                            { sliderImages.Name }
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        );
                                    } ) }
                            </Slider>
                        </div>
                    </Col>
                )
            }
        </>
    )
}