import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"

// Styles
import "../AboutUs/AboutUs.scss"
import CustomButton from "../../../components/CustomButton"
import {ImageModule} from '../../../modules/Image_Module'

// Components
import SectionWrapper from "../../../components/SectionWrapper"
import SectionHeader from "../../../components/SectionHeader"

import PlayVideoIcon from '../../../images/play_video_icon.svg'

const AboutUs = ( { Module, setPlayVideo } ) => {

	//console.log("aboutUsData", Module);
	return (
		<SectionWrapper className={`about-us-wrapper ${Module.Background_Color}`}>
			<Container>
				<Row>
					<Col className={Module.Image_Position === "Left" ? 'custom_margin' : ''}>
						<div className="about-us-container">
							<SectionHeader
								sectionHeaderData={ {
									Title: Module.Heading,
								} }
							/>
							{/* <div className="about-us-subtitle">{ Module.Heading }</div> */}
							{/* <p
								className="about-us-content"
								dangerouslySetInnerHTML={ {
									__html: Module.Content
								} }
							/> */}
							{Module.Content && <div className="about-us-content">{HTMLReactParser(Module.Content)}</div>}

							{Module.CTA_Label && Module.CTA_Link && 
								<div>
									<CustomButton
										value={Module.CTA_Label}
										link={Module.CTA_Link}
										variant="outline"
									/>
								</div>
							}
							
						</div>
					</Col>
					<Col
						lg={ { span: 6, order: Module.Image_Position === "Left" ? 'first' : 'last img_cnt', offset: Module.Image_Position === "Left" ? 0 : 1 } }						
						md={ { span: 12, order: 'first' } }
						xs={ { span: 12, order: 'first' } }
						className="order-sm-first"
					>
						<div className="video-container">
							{/* <img
								src={ require( `../../../images/video.png` ).default }
								alt="about-us"
							/> */}
							<ImageModule ImageSrc={Module.Image} classNames={"image_cnt"}/>
							{Module.Embed_Video_URL && 
								<span className="play_btn" onClick={()=>setPlayVideo(Module.Embed_Video_URL)}><img src={PlayVideoIcon} alt="Play Video - Resiland" /></span>
							}
						</div>
					</Col>
				</Row>
			</Container>
		</SectionWrapper>
	)
}

export default AboutUs
