import * as React from "react"
import { Row, Col } from "react-bootstrap"
import ShowMoreText from 'react-show-more-text';
import dateFormat from "dateformat";
import HTMLReactParser from "html-react-parser"

import {ImageModule} from '../../modules/Image_Module'

// Styles
import "../TestimonialCards/TestimonialCards.scss"


const TestimonialCards = ( { testimonialsCardsData } ) => {

	return (
		<Row className="testimonialcards-wrapper">
			{ testimonialsCardsData &&
				testimonialsCardsData.map( ( testimonialCard, testimonialIndex ) => {

					if(testimonialIndex < 2){
						return (
							<Col
								key={ testimonialIndex }
								sm={ 6 }
								xs={ 12 }
								className={ testimonialIndex > 0 ? 'd-none d-sm-block' : '' }
							>
								<div className="card">
									<div className="card-content">
										<div className="card-description">
											{testimonialCard.reviews.Content && 
											<ShowMoreText
												lines={4}
												more='View More'
												less='View Less'
												className='content-css'
												anchorClass='btn-more review-more'
												expanded={false}> 

												{HTMLReactParser(testimonialCard.reviews.Content)}
											</ShowMoreText> 
											}
											
										</div>
										<div>
											<ClientDetails
												clientProfileData={ testimonialCard.reviews }
											/>
										</div>
									</div>
									<div className="quote-image">
										<i className="icon icon-quote-down"></i>
									</div>
								</div>
							</Col>
						)
					}
					
				} ) }
		</Row >
	)
}

export default TestimonialCards

const ClientDetails = ( { clientProfileData } ) => {
	const { Image, Name, Location, Published_Date, Client_Name } = clientProfileData;
	return (
		<div className="client-details-wrapper">
			<div className="client-profile-picture">
				{/* <img
					src={ require( `../../images/${ Image }` ).default }
					alt={ ClientName || "name" }
				/> */}
				<ImageModule ImageSrc={Image} />
			</div>
			<div>
				<div className="client-details">
					<div className="client-name">{ Client_Name }</div>
					<div className="client-place">{ Location }</div>
					{Published_Date && <div className="date">{dateFormat(Published_Date, "mmm dd, yyyy") }</div>}
				</div>
			</div>
		</div>
	)
}
