import React, { useRef } from "react"
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import HTMLReactParser from "html-react-parser";

// Configs
import multiContentSlider from "../../../utilities/multiContentSlider";
import {ImageModule} from '../../../modules/Image_Module'

// Styles
import './About.scss';

// Components
import NavigationArrow from "../../../components/NavigationArrow";


const About = ({Module}) => {

    const sliderRef = useRef();

    const settings = {
        ...multiContentSlider,
        nextArrow: (
            <NavigationArrow
                type="left"
                sliderRef={ sliderRef }
            />
        ),
        prevArrow: (
            <NavigationArrow
                type="right"
                sliderRef={ sliderRef }
            />
        ),
    };

    return (
        <div className="about-wrapper photo_gal">
            <Container>
                <Row>
                    <Col lg="10" className="content_sec">
                        { Module?.Title && 
                            <h2 className="general-heading">
                                { Module?.Title }
                            </h2>
                        }
                        {Module?.Content && HTMLReactParser(Module?.Content)}
                    </Col>
                </Row>
                
                {Module?.Images?.length > 0 && 
                    <div className="multi-content-slider-wrapper">
                        <Slider
                            { ...settings }
                            ref={ sliderRef }
                        >
                            { Module?.Images?.map( ( sliderImage, sliderIndex ) => {
                                return (
                                    <div
                                        key={ sliderIndex }
                                        className="slider-image-wrapper"
                                    >
                                        <div className="slider-image"><ImageModule ImageSrc={sliderImage}  /></div>
                                    </div>
                                )
                            } ) }
                        </Slider>
                    </div>
                }
                
            </Container>
        </div >
    )
}

export default About
