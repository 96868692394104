import React, { useRef } from "react"
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "@reach/router";

// Configs
import multiContentSlider from "../../utilities/multiContentSlider";

// Styles
import '../../layout/DiscoverMarylebone/About/About.scss';

// Components
import NavigationArrow from "../NavigationArrow";
import SectionHeader from "../SectionHeader";
import {ImageModule} from '../../modules/Image_Module'

import {propertyDetailsLink} from '../common/propertyUtils'


const PropertiesSection = ({Module}) => {

    const sliderRef = useRef();

    const sectionHeaderData = {
        Title: Module.Title,
        SubTitle: Module.Content,
    }

    const settings = {
        ...multiContentSlider,
        nextArrow: (
            <NavigationArrow
                type="left"
                sliderRef={ sliderRef }
            />
        ),
        prevArrow: (
            <NavigationArrow
                type="right"
                sliderRef={ sliderRef }
            />
        ),
    };

    return (
        <div className="about-wrapper properties_section" id="about">
            <Container>
                <Row>
                    <Col lg="12">
                        <SectionHeader
                            sectionHeaderData={ sectionHeaderData }
                        />
                    </Col>
                </Row>
                <div className="multi-content-slider-wrapper">
                    <Slider
                        { ...settings }
                        ref={ sliderRef }
                    >
                        { Module.Recent_Project_Module.map( ( sliderImage, sliderIndex ) => {
                            //const { source, caption } = sliderImage;

                            var link = propertyDetailsLink(sliderImage.property?.department, sliderImage.property?.search_type, sliderImage.property?.slug, sliderImage.property?.id);

                            return (
                                <div
                                    key={ sliderIndex }
                                    className="slider-image-wrapper"
                                >
                                    <Link to={link} className="no_hover">
                                        <ImageModule ImageSrc={sliderImage.property?.Image?.length > 0 ? Module.property.Image[0].url : ""} altText={Module?.property?.display_address+" - Resiland"} />
                                    </Link>
                                </div>
                            )
                        } ) }
                    </Slider>
                </div>
            </Container>
        </div >
    )
}

export default PropertiesSection
