import React from 'react';
import { graphql } from 'gatsby'


import CommonTemplate from './common-template';

export default function defaultTemplate (props){

  var GQLPage = props.data.glstrapi?.article;
  var GQLModules = GQLPage?.Modules;

  var globalModule = props.data.glstrapi?.globalModule;


  return(
    <CommonTemplate GQLPage={GQLPage} GQLModules={GQLModules} globalModule={globalModule} />
  )

}

export const pageQuery = graphql`
  query DeafultQuery($articleId: ID!) {
    glstrapi {

      globalModule {

        Fundamentals_Component {
          Heading
          Content
          Fundamentals_Module {
            id
            Title
            Content
            CTA_Link
            CTA_Label
            Icon {
              alternativeText
              url
            }
          }
        }
      }

      article(id: $articleId) {
        id
        Pagename
        Alias
        Meta_Title
        Meta_Description
        Layout
        Content
        Banner_Title
        Banner_Content
        Banner_CTA_Link
        Banner_CTA_Label
        Banner_CTA_Link_2
        Banner_CTA_Label_2
        Banner_Image {
          url
          alternativeText
        }
        Page_CSS_Class
        imagetransforms
        all_menu {
          id
          Primary_URL
          Page_Name

          Parent_Menu {
            Primary_URL
            Page_Name
            id
          }
          Sub_Menu {
            Primary_URL
            Page_Name
            id
          }
          Secondary_Sub_Menu {
            Primary_URL
            Page_Name
            id
          }
        }
        Modules {
          __typename
          ... on GLSTRAPI_ComponentComponentSliderComponent {
            Slider_Module {
              Banner_Heading
              Banner_Content
              id
              Banner_Image {
                url
                alternativeText
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentIntroComponent {
            id
            Title
            Content
            Video_URL:Embed_Video_URL
            
            Right_Side_Image {
              url
              alternativeText
            }

            Right_Side_Module {
              id
              Title
              Show_Contact_Details
              Show_Call_Back_Button
            }

            Accordian_Section {
              id
              Description
              Heading
              Image {
                url
                alternativeText
              }
            }

            Team_Module {
              team {
                id
                Title
                URL
                Designation
                Email
                Phone_Number
                image {
                  url
                  alternativeText
                }
                
              }
            }

          }

          ... on GLSTRAPI_ComponentComponentRelatedPagesComponent {
            id
            Related_Pages_Module {
              Label
              id
              all_menus {
                Primary_URL
                Secondary_URL
                Parent_Menu {
                  Primary_URL
                }
                Sub_Menu {
                  Primary_URL
                }
                Secondary_Sub_Menu {
                  Primary_URL
                }
                Image {
                  alternativeText
                  url
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentFeaturedProperties {
            id
            Title
            Content
            View_All_Link
            Show_Featured_Properties
            Property_Module {
              id
              Properties {
                _id
                id
                display_address
                slug
                price
                bedroom
                bathroom
                images
                department
                search_type
                status
                imagetransforms
              }
            }
          }
          

          ... on GLSTRAPI_ComponentComponentMiddleContentBlock {
            id
            Heading
            Content
            Embed_Video_URL
            CTA_Link
            CTA_Label
            Image_Position
            Background_Color
            Image {
              url
              alternativeText
            }
          }

          ... on GLSTRAPI_ComponentComponentShowFundamentals {
            id
            Show_Component
          }

          ... on GLSTRAPI_ComponentComponentShowExploreArea {
            id
            Title
            Show_Areas
            Content
          }

          ... on GLSTRAPI_ComponentComponentWhatsHappningComponent {
            id
            Title
            News_Insight_Modules {
              news_insights {
                Title
                URL
                Published_Date
                news_categories {
                  Title
                  id
                  URL
                }
                Image {
                  url
                  alternativeText
                }
                imagetransforms
              }
              id
            }
          }

          ... on GLSTRAPI_ComponentComponentTestimonialsComponent {
            id
            Title
            Content
            Reviews_Module {
              id
              reviews {
                id
                Content
                Location
                Name
                Client_Name
                Published_Date
                Image {
                  url
                  alternativeText
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentRecentProjectsComponent {
            id
            Title
            Content
            Recent_Project_Module {
              property {
                id
                slug
                display_address
                department
                search_type
                images              
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentAccordianComponent {
            id
            Title
            Content            
            Accordian_Module {
              id
              Heading
              Content
              Description
              Image {
                url
                alternativeText
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentShowContactForm {
            id
            Content
            Form_Type
            Show_Component
            Title
          }

          ... on GLSTRAPI_ComponentComponentServiceComponent {
            id
            Title
            CTA_Label
            CTA_Link
            Service_Module {
              id
              Link
              Label
              Image {
                alternativeText
                url
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentCollectionComponent {
            id
            Select_Collection
          }


          ... on GLSTRAPI_ComponentComponentOurBuildsComponent {
            id
            Title
            Our_Buildings_Module {
              Properties {
                _id
                id
                display_address
                slug
                price
                bedroom
                bathroom
                images
                department
                search_type
                status
                imagetransforms
              }
            }
          }


          ... on GLSTRAPI_ComponentComponentBrandLogosComponent {
            id
            Title
            Brand_Logo_Module {
              Link
              id
              Image {
                url
                alternativeText
              }
            }
          }


          ... on GLSTRAPI_ComponentComponentGalleryComponent {
            id
            Title
            Content
            Images {
              alternativeText
              url
            }
          }


          ... on GLSTRAPI_ComponentComponentAdsBlockComponent {
            id
            Title
            Content
            CTA_Link_2
            CTA_Link_1
            CTA_Label_2
            CTA_Label_1
          }

          ... on GLSTRAPI_ComponentComponentVacanciesComponent {
            id
            Heading
            Vacancies_Module {
              career {
                Title
                Location
                URL
                Title_Image {
                  alternativeText
                  url
                }
              }
            }
          }

          ... on GLSTRAPI_ComponentComponentShowTeamComponent {
            id
            Show_Team
            Heading
            CTA_Link
            CTA_Label
          }

          ... on GLSTRAPI_ComponentComponentNewsletterSubscription {
            id
            Show_Newsletter
          }

          
        }
      }
    }
}`
