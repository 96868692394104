import React, { useState, useRef, useEffect } from "react";
import 'react-modal-video/scss/modal-video.scss';
import { Modal } from 'react-bootstrap';
import { useInView } from 'react-intersection-observer';
import getVideoId from 'get-video-id';
import { youtube_parser } from "../common/utils";


import "./FullWidthVideo.scss"

const PlayVideo = (props) => {
    const [ref, inView] = useInView({
        threshold: 0,
        triggerOnce: true,
    });
    //const [show, setShow] = useState(props.isOpen ? props.isOpen : false);
    const [fullscreen, setFullscreen] = useState(true);

    //VIDEO GTM TRACKING
    const url = typeof window !== 'undefined' ? window.location.href : '';

    useEffect(()=>{

        if(props.isOpen === true){
            if (typeof window !== 'undefined') {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'Video Play Btn',
                    'formType': url,
                    'formId': 'Video Play',
                    'formName': 'Video',
                    'formLabel': 'Video'
                });
            }
        }        
    },[props]);
    
    // const trackerVideo = (event) => {
    //     if (typeof window !== 'undefined') {
    //         window.dataLayer = window.dataLayer || [];
    //         window.dataLayer.push({
    //             'event': 'Video Play Btn',
    //             'formType': url,
    //             'formId': 'Video Play',
    //             'formName': 'Video',
    //             'formLabel': 'Video'
    //         });
    //     }
    // }

    var video_url2 = props?.videoUrl && getVideoId(props?.videoUrl);

    //console.log("video_url", video_url2);

    return (
        <React.Fragment>
            {/* <ModalVideo 
            channel='youtube' 
            key={props.key}
            autoplay 
            isOpen={props.isOpen} 
            videoId={props.videoId}
            autoplay={1}
            onClose={ e => props.isCloseFunction(false)}
            /> */}
            <Modal
                show={props.isOpen}
                onHide={() => props.isCloseFunction(false)}
                dialogClassName="modal-fullscreen modal-video"
                aria-labelledby="example-custom-modal-styling-title"
                backdropClassName="video-backdrop"
                fullscreen={fullscreen}
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    {video_url2 &&
                        video_url2?.service === "youtube" ?
                            <iframe width="100%" height="100%" src={`https://www.youtube.com/embed/${video_url2?.id}?autoplay=1`} title="" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        :
                        video_url2?.service === "vimeo" ?
                            <iframe width="100%" height="100%" src={`https://player.vimeo.com/video/${video_url2?.id}?autoplay=1`} title="" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                        :
                        <iframe width="100%" height="100%" src={`${props.videoUrl}`} title="" frameborder="0" allowfullscreen></iframe>
                    }
                </Modal.Body>
            </Modal>

        </React.Fragment>
    )
    
};

export default PlayVideo;