import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import HTMLReactParser from "html-react-parser"

// Styles
import "./Content.scss"

// Components
import SectionWrapper from "../SectionWrapper"

const ArticleDetails = ( { GQLPage } ) => {
    return (
        <SectionWrapper className="article-details-wrapper">
            <Container>
                <Row>
                    <Col
                        lg={ {
                            span: 8,
                            offset: 2
                        } }
                    >
                        <div className="article-header">
                            <h1 className="general-heading">{GQLPage.Pagename}</h1>
                        </div>

                        <div className="article-body">
                            {GQLPage.Content && HTMLReactParser(GQLPage.Content)}
                        </div>
                    </Col>
                </Row>
            </Container>
        </SectionWrapper>
    )
}

export default ArticleDetails
