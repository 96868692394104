import React, { useRef } from "react"
import { Col, Container, Row, Card } from "react-bootstrap";
import Slider from "react-slick";
import { Link } from "@reach/router";
// Styles
import '../AreaGuides/AreaGuides.scss';
import './Job.scss';

// Components
import multiContentSlider from "../../utilities/multiContentSlider";
import NavigationArrow from "../NavigationArrow";

import SectionWrapper from "../SectionWrapper";
import SectionHeader from "../SectionHeader";
import {ImageModule} from '../../modules/Image_Module'
import JobArrow from '../../images/job_arrow.svg'

// Components
import {CustomLinks} from '../common/menuUtils';

import {GetCareers} from "../../queries/common_use_query"

const Careers = ( props ) => {
    const {loading, error, data} = GetCareers();

    const sliderRef = useRef();

    const sectionHeaderData = {
        Title: props.Module.Heading
    }

    // const settings = {
    //     ...multiContentSlider,
    //     infinite: data && data?.careers?.length > 3 ? true : false,
    //     nextArrow: (
    //         <NavigationArrow
    //             type="left"
    //             sliderRef={ sliderRef }
    //         />
    //     ),
    //     prevArrow: (
    //         <NavigationArrow
    //             type="right"
    //             sliderRef={ sliderRef }
    //         />
    //     ),
    // };

    return (
        <SectionWrapper
            className={ `layout-01-wrapper area-guides-wrapper` }
        >
            <Container>
                <Row>
                    <Col>
                        <div className="layout-01-header-section">
                            <SectionHeader
                                sectionHeaderData={ sectionHeaderData }
                            />
                        </div>
                    </Col>
                </Row>

                { data && data?.careers?.length > 0 && (
                    <div className="multi-content-slider-wrapper job-details">
                        {/* <Slider
                            { ...settings }
                            ref={ sliderRef }
                        > */}
                            { data?.careers?.map( ( sliderDataItem, sliderDataIndex ) => {

                                var job_link = '/'+CustomLinks.careers+'/'+sliderDataItem.URL+'/'
                                return (
                                    // <div
                                    //     key={ sliderDataIndex }
                                    //     className="slider-image-wrapper"
                                    // >
                                    //     <div className="single-area-card-wrapper">
                                    //         <Card>
                                    //             <div className="card-container-image">
                                    //                 <Link to={job_link}>
                                    //                     <ImageModule ImageSrc={ sliderDataItem?.Title_Image } classNames={"card-img-top"} />
                                    //                 </Link>
                                    //             </div>
                                    //             <Card.Body>
                                    //                 <Card.Title>
                                    //                     <Link to={job_link}>{ sliderDataItem.Title }</Link>
                                    //                 </Card.Title>
                                    //                 <Card.Text className="card-content">
                                    //                     <b>Location:</b> { sliderDataItem.Location }
                                    //                 </Card.Text>

                                    //                 <Link to={job_link} className="job_arw"><img  src={JobArrow} alt={ sliderDataItem.Title } /></Link>
                                    //             </Card.Body>
                                    //         </Card>
                                    //     </div>
                                    // </div>
                                    <Card className="job_card">
                                        <Card.Title>
                                            <Link to={job_link}>{ sliderDataItem?.Title }</Link>
                                            <p className="card-content d-block d-md-none">
                                                { sliderDataItem?.Job_Positions }
                                            </p>
                                        </Card.Title>
                                        <Card.Text className="card-content d-none d-md-block">
                                            { sliderDataItem?.Job_Positions }
                                        </Card.Text>
                                        <Link to={job_link} className="job_arw">
                                            <div className="d-none d-sm-block">View Details</div> 
                                            <img className="d-block d-sm-none" src={JobArrow} alt={ sliderDataItem.Title } />
                                        </Link>
                                    </Card>
                                )
                            } ) }
                        {/* </Slider> */}
                    </div>
                ) }
            </Container>
        </SectionWrapper>
    )
}

export default Careers