import React, {useEffect, useState} from "react"
import { Container, Row, Col } from "react-bootstrap"

// Styles
import "./NewsDetails.scss"

// Components
import SectionWrapper from "../../../components/SectionWrapper"
import FilterContent from "components/FilterContent"
import SingleNewsCard from "components/SingleNewsCard"
import CustomButton from "components/CustomButton"

import {GetNewsList, GetNewsCategories} from "../../../queries/common_use_query"

const postsPerPage = 12;
const initialPostsPerPage = 13;
let arrayForHoldingPosts = [];

const NewsListPage = () => {
    const layout = {
        lg: 4,
        md: 6,
        sm: 12
    };

    const [postsToShow, setPostsToShow] = useState([]);
    const [newsList, setNewsList] = useState([]);
    const [next, setNext] = useState(initialPostsPerPage);
    const [ selectedCategory, setSelectedCategory ] = useState("All");

    const {loading, error, data} = GetNewsList();
    const {data:cat_data} = GetNewsCategories();

    // useEffect(() => {
    //     setPostPerPageLoad(13);
    // }, []);

    useEffect(() => {
        if(data && data?.newsAndInsights && data.newsAndInsights.length > 0){
            var filter_by_cat = [];
            emptyArraySet();
            if(selectedCategory === "All"){
                setNewsList(data?.newsAndInsights);
            } else{
                data.newsAndInsights.map((item, index) => {
                    if(item?.news_categories.length > 0 && item?.news_categories?.some(item2 =>  item2.Title === selectedCategory.Title)){
                        filter_by_cat.push(item);
                    }
                })
                setNewsList(filter_by_cat);
            }
            
        } 
        
    }, [data, selectedCategory]);
    
    const loopWithSlice = (start, end) => {
        const sliced_Posts = newsList.slice(start, end);
        arrayForHoldingPosts = [...arrayForHoldingPosts, ...sliced_Posts];
        setPostsToShow(arrayForHoldingPosts);
    };

    const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
    };

    useEffect(() => {
        loopWithSlice(0, initialPostsPerPage);
    }, [newsList]);

    const emptyArraySet = () => {
        arrayForHoldingPosts = [];
        setNext(postsPerPage);
    }

    //console.log("next", next);

    //console.log("selectedCategory", selectedCategory, newsList)
    return (
        <SectionWrapper className="news-details-wrapper">
            <Container>
                <Row>
                    <Col>
                        <FilterContent
                            categories={ cat_data && cat_data.newsCategories}
                            content={ postsToShow}
                            CardComponent={ SingleNewsCard }
                            layout={ layout }
                            showFeaturedCard
                            selectedCategory={selectedCategory}
                            setSelectedCategory={setSelectedCategory}
                        />
                        <div>
                        {next < newsList.length && 
                        <CustomButton
                            variant="outline"
                            onClick={() => {handleShowMorePosts(); }}
                            value="LOAD MORE"
                        />
                        }
                        </div>
                    </Col>
                </Row>
            </Container>
        </SectionWrapper>
    )
}

export default NewsListPage
